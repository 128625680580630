<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import EditData from './edit-data';
import EditPassword from './edit-password';

export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, EditData, EditPassword },
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Profile",
          href: "/profile"
        },
      ]
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <edit-data></edit-data>
      </div>
      <div class="col-lg-6">
        <edit-password></edit-password>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>