<script>
import Swal from "sweetalert2";
import cookie from 'js-cookie';

import {
  required,
  email,
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  data() {
    return {
      form: {
        name: this.$store.state.userdata.name,
        phone: this.$store.state.userdata.phone,
        email: this.$store.state.userdata.email,
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
    };
  },
  validations: {
    form: {
      name: { required },
      phone: { required },
      email: { required, email }
    },
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        // do your submit logic here
        let param = new FormData();
        param.append('name', this.form.name) 
        param.append('email', this.form.email)
        param.append('phone', this.form.phone)

        this.$axios.put(`/staff/update/contact`, param)
        .then( (result) => { 
          console.log(result.data)
          this.isError = false
          this.errorMessage = null
          // update vuex
          this.$store.commit("UPDATE_PROFILE", {
            name: this.form.name,
            phone: this.form.phone,
            email: this.form.email,
          });
          // update cookie
          let userdata = cookie.getJSON('userdata')
          userdata.name = this.form.name
          userdata.phone = this.form.phone
          userdata.email = this.form.email
          cookie.set("userdata", userdata, { expires: 1 }); 
          // notif
          Swal.fire("Success", "Profile Updated Successfully", "success");
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-card-title>
        <h5 class="card-title">Update Data Profile</h5>
      </b-card-title>
      <hr>
      <b-alert
        v-model="isError"
        variant="danger"
        class="mt-3"
        dismissible
      >{{errorMessage}}</b-alert>
      <form action="#" @submit.prevent="onSubmit">
        <div class="form-group">
          <label>Name</label>
          <input
            v-model="form.name"
            type="text"
            class="form-control"
            name="name"
            :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
          />
          <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
            <span v-if="!$v.form.name.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label>Phone</label>
          <input
            v-model="form.phone"
            type="number"
            class="form-control"
            name="phone"
            :class="{ 'is-invalid': typesubmit && $v.form.phone.$error }"
          />
          <div v-if="typesubmit && $v.form.phone.$error" class="invalid-feedback">
            <span v-if="!$v.form.phone.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label>E-Mail Address</label>
          <div>
            <input
              v-model="form.email"
              type="email"
              name="email"
              class="form-control"
              :class="{ 'is-invalid': typesubmit && $v.form.email.$error }"
            />
            <div v-if="typesubmit && $v.form.email.$error" class="invalid-feedback">
              <span v-if="!$v.form.email.required">This value is required.</span>
              <span v-if="!$v.form.email.email">This value should be a valid email.</span>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div>
            <button type="submit" class="btn btn-primary">Save Change</button>
          </div>
        </div>
      </form>
    </div>
    <!-- end card-body -->
  </div>
</template>