<script>
import Swal from "sweetalert2";

import {
  required,
  minLength
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  data() {
    return {
      form: {
        current_password: null,
        new_password: null,
        confirm_new_password: null
      },
      typesubmit: false,
      isError: false,
      errorMessage: null
    };
  },
  validations: {
    form: {
      current_password: { required, minLength: minLength(8) },
      new_password: { required, minLength: minLength(8) },
      confirm_new_password: { required, minLength: minLength(8) }
    },
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars

    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        // do your submit logic here
        let param = new FormData();
        param.append('current_password', this.form.current_password) 
        param.append('password', this.form.new_password) 
        param.append('password_confirmation', this.form.confirm_new_password) 

        this.$axios.put('/staff/update/password', param)
        .then( (result) => { 
          console.log(result.data)
          this.isError = false
          this.errorMessage = null
          this.form = {
            current_password: null,
            new_password: null,
            confirm_new_password: null
          }
          Swal.fire("Success", "Password Updated Successfully", "success");
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-card-title>
        <h5 class="card-title">Update Password</h5>
      </b-card-title>
      <hr>
      <b-alert
        v-model="isError"
        variant="danger"
        class="mt-3"
        dismissible
      >{{errorMessage}}</b-alert>
      <form action="#" @submit.prevent="onSubmit">

        <div class="form-group">
          <label>Current Password</label>
          <div>
            <input
              v-model="form.current_password"
              type="password"
              name="current_password"
              class="form-control"
              :class="{ 'is-invalid': typesubmit && $v.form.current_password.$error }"
            />
            <div v-if="typesubmit && $v.form.current_password.$error" class="invalid-feedback">
              <span v-if="!$v.form.current_password.required">This value is required.</span>
              <span v-if="!$v.form.current_password.minLength">Password must be at least 8 characters.</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>New Password</label>
          <div>
            <input
              v-model="form.new_password"
              type="password"
              name="new_password"
              class="form-control"
              :class="{ 'is-invalid': typesubmit && $v.form.new_password.$error }"
            />
            <div v-if="typesubmit && $v.form.new_password.$error" class="invalid-feedback">
              <span v-if="!$v.form.new_password.required">This value is required.</span>
              <span v-if="!$v.form.new_password.minLength">Password must be at least 8 characters.</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Confirm New Password</label>
          <div>
            <input
              v-model="form.confirm_new_password"
              type="password"
              name="confirm_new_password"
              class="form-control"
              :class="{ 'is-invalid': typesubmit && $v.form.confirm_new_password.$error }"
            />
            <div v-if="typesubmit && $v.form.confirm_new_password.$error" class="invalid-feedback">
              <span v-if="!$v.form.confirm_new_password.required">This value is required.</span>
              <span v-if="!$v.form.confirm_new_password.minLength">Password must be at least 8 characters.</span>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div>
            <button type="submit" class="btn btn-primary">Change Password</button>
          </div>
        </div>
      </form>
    </div>
    <!-- end card-body -->
  </div>
</template>